'use client'
import type { Step } from '@capabilities/search-steps'
import { MarkdownText } from '@capabilities/ui-elements'
import { listify, sift } from 'radash'
import { useState } from 'react'
import { Button, Checkbox, Divider, FormControl, Text, View } from 'reshaped'
import type { StepRendererParams } from '../../createStepRenderer'
import { DisplayFields } from './DisplayFields'
import { EditableFields } from './EditableFields'

type FieldValues = Partial<Record<Step['fields'][number]['name'], string>>

export const ReviewPrefill: StepRendererParams<'PrefillReview'> = ({
  headline,
  StepLayout,
  loading,
  fields,
  status,
  subtext,
}) => {
  const [
    ssnField,
    { label: smsLabel, name: smsName, description: smsDescription },
    { label: consentButtonLabel, name: consentButtonName },
    tcpaField,
    fcraField,
    firstNameField,
    lastNameField,
    address1Field,
    address2Field,
    cityField,
    stateField,
    zipcodeField,
    phoneField,
    dobField,
  ] = fields

  const [editable, setEditableFields] = useState({
    [firstNameField.name]: firstNameField.value,
    [lastNameField.name]: lastNameField.value,
    [address1Field.name]: address1Field.value,
    [address2Field.name]: address2Field.value,
    [cityField.name]: cityField.value,
    [stateField.name]: stateField.value,
    [zipcodeField.name]: zipcodeField.value,
  })

  const updateEditable = (fields: FieldValues) =>
    setEditableFields((prev) => ({ ...prev, ...fields }))

  const formattedAddress = sift([
    editable['lead.personalInformation.address1'],
    editable['lead.personalInformation.address2'],
    editable['lead.personalInformation.city'],
    editable['lead.personalInformation.state'],
    editable['lead.personalInformation.zipcode'],
  ]).join(', ')

  const hiddenFields = [
    ...listify(editable, (key, value) => ({ name: key, value })),
    phoneField,
    dobField,
    tcpaField,
    fcraField,
  ]

  return (
    <View gap={6}>
      <View paddingBlock={4} paddingInline={6} backgroundColor="neutral-faded">
        <Text color="neutral" weight="bold" variant="body-3">
          {subtext}
        </Text>
      </View>
      <StepLayout.Title title={headline} />

      <EditableFields
        label="Name"
        title="What's your name?"
        value={`${editable['lead.personalInformation.firstName']} ${editable['lead.personalInformation.lastName']}`}
        fields={[firstNameField, lastNameField]}
        update={updateEditable}
      />
      <EditableFields
        label="Address"
        title="What's your address?"
        value={formattedAddress}
        fields={[address1Field, address2Field, cityField, stateField, zipcodeField]}
        update={updateEditable}
      />
      <DisplayFields label="Phone Number" value={phoneField.value} />
      <DisplayFields label="SSN" value={ssnField.value} />
      <DisplayFields label="Date of Birth" value={dobField.value} />

      <View maxWidth={50}>
        <Button
          type="submit"
          fullWidth
          size="large"
          variant="solid"
          color="positive"
          attributes={{ name: consentButtonName }}
          loading={loading || status === 'success'}
        >
          <Text variant="body-3">{consentButtonLabel}</Text>
        </Button>
      </View>
      <FormControl size="large">
        <View direction="row" gap={2} wrap>
          <Checkbox name={smsName} attributes={{ style: { alignItems: 'start' } }}>
            <Text color="neutral-faded" variant="body-3" weight="bold">
              {smsLabel}
            </Text>
            <Divider blank vertical />
            <Text color="neutral-faded" variant="body-3">
              {smsDescription}
            </Text>
          </Checkbox>
        </View>
      </FormControl>
      {hiddenFields.map(({ name, value }) => (
        <input key={name} name={name} type="hidden" value={value} />
      ))}

      <View>
        <Text color="neutral-faded" variant="body-3">
          <MarkdownText content={fcraField.value} />
          <MarkdownText content={tcpaField.value} />
        </Text>
      </View>
    </View>
  )
}
