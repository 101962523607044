'use client'
import { useMask } from '@react-input/mask'
import { FormControl, TextField, View } from 'reshaped'
import type { StepRendererParams } from '../createStepRenderer'
import { DateField } from '../fields/DateField'

export const PrefillDobPhoneInputStep: StepRendererParams<'PrefillDobPhone'> = ({
  headline,
  StepLayout,
  errors,
  loading,
  fields,
  subtext,
}) => {
  const inputRef = useMask({
    mask: '___-___-____',
    separate: true,
    replacement: { _: /\d/ },
  })
  const [
    { label: phoneLabel, placeholder: phonePlaceholder, name: phoneName },
    { label: dobLabel, placeholder: dobPlaceholder, name: dobName, value: dobValue },
  ] = fields
  return (
    <View gap={6}>
      <StepLayout.Title title={headline} />
      <StepLayout.Subtext subtext={subtext} />
      <FormControl
        size="large"
        hasError={errors?.some((error) => error.name === phoneName)}
      >
        <FormControl.Label>{phoneLabel}</FormControl.Label>
        <TextField
          size="large"
          name={phoneName}
          prefix="+1"
          placeholder={phonePlaceholder}
          inputAttributes={{
            type: 'tel',
            required: true,
            ref: inputRef,
          }}
        />
        <FormControl.Error>
          {errors?.find((error) => error.name === phoneName)?.message}
        </FormControl.Error>
      </FormControl>
      <FormControl
        size="large"
        hasError={errors?.some((error) => error.name === dobName)}
      >
        <DateField
          size="large"
          label={dobLabel}
          name={dobName}
          placeholder={dobPlaceholder}
          defaultValue={dobValue}
        />
        <FormControl.Error>
          {errors?.find((error) => error.name === dobName)?.message}
        </FormControl.Error>
      </FormControl>
      <StepLayout.SubmitButton loading={loading} />
    </View>
  )
}
