import { objectify } from 'radash'
import { useState } from 'react'
import {
  Actionable,
  Button,
  Dismissible,
  FormControl,
  Modal,
  Text,
  TextField,
  View,
  useToggle,
} from 'reshaped'
import type { Field, Step } from '../../../../steps/src'
import { Pencil } from '../icons/Pencil'

type FieldValues = Partial<Record<Step['fields'][number]['name'], string>>

export const EditableFields = ({
  label,
  value,
  fields,
  title,
  update,
}: {
  label: string
  value: string
  title: string
  fields: Array<Field<string, string>>
  update: (fields: FieldValues) => void
}) => {
  const { active, activate, deactivate } = useToggle(false)
  const [editableFields, setFields] = useState(
    objectify(
      fields,
      (f) => f.name,
      (f) => f.value,
    ),
  )

  const handleSave = () => {
    update(editableFields)
    deactivate()
  }

  return (
    <View gap={2}>
      <Text color="neutral-faded" variant="body-3" weight="regular">
        {label}
      </Text>
      <Text color="neutral" weight="bold" variant="body-3">
        <View gap={2} direction="row">
          <span className="dd-privacy-mask">{value}</span>
          <Actionable
            onClick={() => activate()}
            attributes={{
              'aria-label': `Edit ${label}`,
            }}
          >
            <Pencil />
          </Actionable>
        </View>
      </Text>
      <Modal active={active} onClose={deactivate} disableCloseOnOutsideClick>
        <View gap={6}>
          <Dismissible onClose={deactivate} closeAriaLabel="Close modal">
            <Modal.Title>
              <Text variant="title-4">{title}</Text>
            </Modal.Title>
          </Dismissible>
          {fields?.map((field) => (
            <FormControl key={field.name} size="large">
              <FormControl.Label>{field.label}</FormControl.Label>
              <TextField
                size="large"
                value={editableFields[field.name]}
                name={field.name}
                placeholder={field.placeholder}
                onChange={(e) => {
                  setFields((prev) => ({ ...prev, [field.name]: e.value }))
                }}
                attributes={{
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      handleSave()
                    }
                  },
                }}
              />
            </FormControl>
          ))}
          <View maxWidth={50}>
            <Button
              onClick={() => handleSave()}
              type="button"
              fullWidth
              size="large"
              variant="solid"
              color="positive"
            >
              Save
            </Button>
          </View>
        </View>
      </Modal>
    </View>
  )
}
