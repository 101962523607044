import { Text, View } from 'reshaped'

export const DisplayFields = ({ label, value }: { label: string; value: string }) => {
  return (
    <View gap={2}>
      <Text color="neutral-faded" variant="body-3" weight="regular">
        {label}
      </Text>
      <Text color="neutral" weight="bold" variant="body-3" className="dd-privacy-mask">
        {value}
      </Text>
    </View>
  )
}
