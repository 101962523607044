'use client'

import type { RendererSteps } from '../../../steps/src'
import { createStepRenderer } from '../createStepRenderer'
import { AmountInputStep } from '../steps/AmountInputStep'
import { PrefillDobInputStep } from '../steps/PrefillDobInputStep'
import { PrefillDobPhoneInputStep } from '../steps/PrefillDobPhoneInputStep'
import { ReviewPrefill } from '../steps/PrefillReviewStep/PrefillReviewStep'

export const ReviewPrefillStepRenderer =
  createStepRenderer<RendererSteps['PrefillReview']>(ReviewPrefill)

export const AmountInputStepRenderer =
  createStepRenderer<RendererSteps['AmountInput']>(AmountInputStep)

export const PrefillDobStepRenderer =
  createStepRenderer<RendererSteps['PrefillDob']>(PrefillDobInputStep)

export const PrefillDobPhoneStepRenderer = createStepRenderer<
  RendererSteps['PrefillDobPhone']
>(PrefillDobPhoneInputStep)
