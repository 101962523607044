export const Pencil = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6668 2.61458C12.5067 2.61458 12.3482 2.64611 12.2003 2.70737C12.0524 2.76863 11.918 2.85842 11.8048 2.97161L2.93002 11.8464L2.28358 14.2167L4.65388 13.5703L13.5287 4.69546C13.6419 4.58227 13.7317 4.4479 13.7929 4.30001C13.8542 4.15212 13.8857 3.99361 13.8857 3.83353C13.8857 3.67346 13.8542 3.51495 13.7929 3.36706C13.7317 3.21917 13.6419 3.0848 13.5287 2.97161C13.4155 2.85842 13.2811 2.76863 13.1332 2.70737C12.9853 2.64611 12.8268 2.61458 12.6668 2.61458ZM11.69 1.47553C11.9997 1.34727 12.3316 1.28125 12.6668 1.28125C13.0019 1.28125 13.3338 1.34727 13.6435 1.47553C13.9531 1.6038 14.2345 1.7918 14.4715 2.0288C14.7085 2.2658 14.8965 2.54716 15.0248 2.85682C15.153 3.16648 15.219 3.49837 15.219 3.83353C15.219 4.1687 15.153 4.50059 15.0248 4.81025C14.8965 5.11991 14.7085 5.40127 14.4715 5.63827L5.4715 14.6383C5.38946 14.7203 5.28744 14.7795 5.17551 14.81L1.50884 15.81C1.27803 15.873 1.03119 15.8074 0.862026 15.6383C0.692859 15.4691 0.627307 15.2223 0.690254 14.9915L1.69025 11.3248C1.72078 11.2129 1.77999 11.1108 1.86203 11.0288L10.862 2.0288C11.099 1.7918 11.3804 1.6038 11.69 1.47553Z"
        fill="currentColor"
      />
    </svg>
  )
}
