'use client'
import { Button, Text, View } from 'reshaped'

type LayoutProps = {
  SubmitButton: React.FC<{ disabled?: boolean; loading?: boolean }>
  Title: React.FC<{ title: string }>
  Subtext: React.FC<{ subtext?: string }>
}

const SubmitButton: LayoutProps['SubmitButton'] = ({ disabled, loading }) => {
  return (
    <View maxWidth={50}>
      <Button
        type="submit"
        fullWidth
        size="large"
        variant="solid"
        color="positive"
        disabled={disabled}
        loading={loading}
      >
        Continue
      </Button>
    </View>
  )
}

const Title: LayoutProps['Title'] = ({ title }) => <Text variant="title-4">{title}</Text>
const Subtext: LayoutProps['Subtext'] = ({ subtext }) =>
  subtext && (
    <View.Item gapBefore={4}>
      <Text color="neutral-faded" variant="body-3">
        {subtext}
      </Text>
    </View.Item>
  )

export const StepLayout = { Title, SubmitButton, Subtext }
