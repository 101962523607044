'use client'
import type { LeadCreateData } from '@capabilities/offers-pipeline'
import type { Flow, Step } from '@capabilities/search-steps'
import type { PropsWithChildren } from 'react'
import { useFormState } from 'react-dom'
import { handleStepAction } from '../phases/actions'
import { StepContext } from './StepContext'
import { useRedirectOnSuccess } from './hooks'

type ContextProviderProps = PropsWithChildren<{
  leadUuid: Exclude<LeadCreateData['uuid'], undefined>
  slug: Step['slug']
  cnfBasePathname: string
  currentFlow: Flow
}>

export const ContextProvider = ({
  children,
  slug,
  leadUuid,
  cnfBasePathname,
  currentFlow,
}: ContextProviderProps) => {
  const [state, formAction] = useFormState(
    handleStepAction.bind(null, leadUuid, slug, currentFlow),
    undefined,
  )
  useRedirectOnSuccess(state, cnfBasePathname)

  return (
    <StepContext.Provider value={{ state }}>
      <form action={formAction}>{children}</form>
    </StepContext.Provider>
  )
}
