'use client'
import type { LeadCreateData } from '@capabilities/offers-pipeline'
import type { Step } from '@capabilities/search-steps'
import { getCNFAwareURLClient } from '@utilities/unsafe'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { z } from 'zod'

type Slug = Step['slug']

/**
 * Build the search params for the POST to the lead creation endpoint.
 *
 * @param params the search params
 * @param initialLeadUuid the provided lead UUID
 * @returns a URLSearchParams object
 */
export const buildSearchParams = (
  params: ReturnType<typeof useSearchParams>,
  initialLeadUuid: LeadCreateData['uuid'],
) => {
  const search = new URLSearchParams(params)

  if (initialLeadUuid) search.set('lead-uuid', initialLeadUuid)

  return search
}

/**
 * @deprecated This shouldn't be used, it's a function of needing to fox server actions
 *
 * Creates a lead using an assumed API route. Once we get regular server
 * actions working this function should be removed in favor of calling the
 * action directly.
 *
 * @param pathname the current path
 * @returns the lead UUID
 */
const unsafeCreateALead = async (
  pathname: ReturnType<typeof usePathname>,
  params: ReturnType<typeof useSearchParams>,
  initialLeadUuid: LeadCreateData['uuid'],
) => {
  const schema = z.object({ leadUuid: z.string() })
  const endpoint = getCNFAwareURLClient(pathname, '/search/api/lead')
  const search = buildSearchParams(params, initialLeadUuid)
  const response = await fetch(`${endpoint}?${search.toString()}`, {
    method: 'POST',
  })
  const data = await response.json()

  return schema.parse(data)
}

/**
 * Custom hook for creating a lead if not already initialized. Will use the
 * provided lead create data to initialize the lead.
 *
 * @param initialLeadUuid the lead UUID, generated by cnf-services and passed as context
 * @returns isLoaded whether the lead has been initialized with the search experience
 */
export const useInitializeLeadUuid = (
  initialLeadUuid: LeadCreateData['uuid'],
  isInitialized = false,
) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const [isReady, setIsReady] = useState(isInitialized)

  useEffect(() => {
    if (!isReady) {
      unsafeCreateALead(pathname, searchParams, initialLeadUuid).then(() => {
        setIsReady(true)
      })
    }
  }, [pathname, searchParams, initialLeadUuid, isReady])

  return isReady
}
