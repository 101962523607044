import type { Step } from '@capabilities/search-steps'
import { useParams, usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

type Slug = Step['slug']
/**
 * This is a hack to update the URL pathname when the step slug is missing after being redirected.
 * This is caused by an issue with the routing service not updating the URL pathname after a server redirect.
 * This ensures the current path reflects the slug, avoiding inconsistencies between the URL and the step being displayed.
 * Read more on this issue: https://github.com/EVENFinancial/cnf-services/issues/126
 * @param initialStepSlug The initial step slug that should be in the URL.
 */
export const useUpdateInitialStepPathname = (initialStepSlug: Slug) => {
  const params = useParams<{ slug: Slug }>()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    // The slug should always be apart of the pathname, if it's not, then we need to apply this hack
    if (!pathname.includes(params.slug) && initialStepSlug === params.slug) {
      const searchString = searchParams.toString()
      const newPath = `${pathname}/${params.slug}${
        searchString ? `?${searchString}` : ''
      }`
      // window history replace is used to replace incorrect URL and not trigger a page refresh
      window.history.replaceState({}, '', newPath)
    }
  }, [pathname, params.slug, initialStepSlug, searchParams])
}
