'use client'

import type {
  Base,
  RendererSteps,
  ValidationError,
  ValidationResult,
} from '@capabilities/search-steps'
import { useSearchStepContext } from '@experiences/search'
import { useFormStatus } from 'react-dom'
import { StepLayout } from './rendererLayout'

type Props<T> = T & {
  StepLayout: typeof StepLayout
  loading: boolean | undefined
  errors: ValidationError['errors'] | undefined
  status: ValidationResult['status'] | undefined
}

export const createStepRenderer = <T extends Base>(Component: React.FC<Props<T>>) => {
  return ({ ...props }: T) => {
    const { state } = useSearchStepContext()
    const errors = state?.status === 'error' ? state.errors : undefined
    // Once we upgrade to React 19, we can remove this hook since useActionState will include the pending state for server action requests.
    const { pending } = useFormStatus()

    return (
      <Component
        errors={errors}
        status={state?.status}
        loading={pending}
        StepLayout={StepLayout}
        {...props}
      />
    )
  }
}

export type StepRendererParams<TStepType extends keyof RendererSteps> = Parameters<
  typeof createStepRenderer<RendererSteps[TStepType]>
>[0]
