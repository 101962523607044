'use client'
import type { Flow, Step } from '@capabilities/search-steps'
import { Loading } from '@capabilities/search-ui'
import type { PropsWithChildren } from 'react'
import { ContextProvider } from './ContextProvider'
import { useInitializeLeadUuid } from './hooks'
import { useUpdateInitialStepPathname } from './hooks/useUpdateInitialStepPathname'

export type StepProviderProps = PropsWithChildren<{
  slug: Step['slug']
  initialStepSlug: Step['slug']
  leadUuid: string
  isInitialized: boolean
  cnfBasePathname: string
  currentFlow: Flow
}>

export const StepProvider = ({
  children,
  slug,
  leadUuid,
  initialStepSlug,
  isInitialized,
  cnfBasePathname,
  currentFlow,
}: StepProviderProps) => {
  useUpdateInitialStepPathname(initialStepSlug)
  const isReady = useInitializeLeadUuid(leadUuid, isInitialized)

  return isReady ? (
    <ContextProvider
      cnfBasePathname={cnfBasePathname}
      slug={slug}
      leadUuid={leadUuid}
      currentFlow={currentFlow}
    >
      {children}
    </ContextProvider>
  ) : (
    <Loading />
  )
}
